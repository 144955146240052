export default {
	name: "ExcelAddIns",

	data() {
		return {
			ExcelDataValidator: {
				Version: null,
				URL: null
			},
			CertificateURL: null
		};
	},

	methods: {
	},

	async mounted() 
	{
		var baseHttpStClickonce = `${Settings.StaticURL}/Clickonce/`;

		this.ExcelDataValidator.Version = await GD_API.GetParameterValue("ESF.DocMan.FileSync.Version");
		
		if (Settings.EnvironmentIsProduction)
		{
			this.CertificateURL = `${baseHttpStClickonce}/CACertificate/code.pysesfapps.com_CA.zip`;
			this.ExcelDataValidator.URL = `${baseHttpStClickonce}DataMan/ExcelValidator/setup.exe`;
		}
		else
		{
			this.CertificateURL = `${baseHttpStClickonce}/CACertificate/code.pysesfapps.com_CA.zip`;
			this.ExcelDataValidator.URL = `${baseHttpStClickonce}${Settings.EnvironmentName}/DataMan/ExcelValidator/setup.exe`;
		}
	}

};